import React, {PureComponent} from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

import { generateBlogPath } from '../../../../utils/urlHelpers/blog';
import { setGenericEvent } from '../../../../store/actions/dataLayer';
import './styles.css';
import {getRouteConstantsFromI18n} from '../../../../tppServices/translations/constants';
import { getMessages } from '../../../../tppServices/translations/messages';

class BlogNavbar extends PureComponent {

  trackGenericClick = (event) => () => {
    if (event) {
      this.props.setGenericEvent('blog - navigation', event.action, event.label);
    }
  }

  render() {
    const { categories, intl: { formatMessage: t } } = this.props;
    const routeConstants = getRouteConstantsFromI18n();
    const { BLOG_URL_ROOT } = routeConstants;
    const homeNavItem = {name: 'home', event: { action: 'blog', label: 'home' }};
    const messages = getMessages();

    return (
      <div className="blog-navbar scroll">
        <nav>
          <ul>
            <li key={homeNavItem.name}>
              <a
                href={BLOG_URL_ROOT}
                onClick={this.trackGenericClick(homeNavItem.event)}
              >
                {capitalize(t(messages.blog.home))}
              </a>
            </li>
            {categories.map(
              ({name, event }, index) =>
                <li key={index}>
                  <a
                    href={generateBlogPath({}, {category: name})}
                    onClick={this.trackGenericClick(event)}
                  >
                    {capitalize(t(messages.blog.categories[name]))}
                  </a>
                </li>
            )}
          </ul>
        </nav>
      </div>
    );
  }

}

BlogNavbar.propTypes = {
  categories: PropTypes.array.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  setGenericEvent: PropTypes.func.isRequired,
};

export default connect(
  null,
  (dispatch) => bindActionCreators({
    setGenericEvent
  }, dispatch)
)(injectIntl(BlogNavbar));
