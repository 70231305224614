import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getDefaultAdAlt } from '../../../../utils/ads/adsUtils';

const RegularAd = ({ adParams, adSlot, adPlacement }) => {
  const imgAlt = getDefaultAdAlt(adPlacement?.alt_text);
  const trackPixel = adPlacement?.tracking_pixel;
  const elegibleUrl = adPlacement?.eligible_url;
  const viewableUrl = adPlacement?.viewable_url;
  const adUrl = adPlacement?.image_url;
  // these lines will be duplicated on the NativeAd component because the components
  // will be replaced by the one in the ui-library
  const adRef = useRef(null);
  const viewableImgRef = useRef(null);
  useEffect(() => {
    if (!('IntersectionObserver' in window)) {
      return;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && viewableImgRef.current && viewableUrl) {
          viewableImgRef.current.src = viewableUrl;
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    adRef.current && observer.observe(adRef.current);

    return () => {
      observer.disconnect();
    };
  }, [viewableUrl]);

  return (
    <div ref={adRef} id={`ad-${adSlot}`} className={adParams[adSlot]?.classes}>
      <a className="adslot-target" href={adPlacement?.redirect_url} rel="noopener noreferrer"
         target="_blank">
        <img alt={imgAlt} src={adUrl} />
        { trackPixel && <img alt={`${imgAlt}-pixel`} className="tracking-pixel" src={trackPixel} /> }
        { elegibleUrl && <img alt={`${imgAlt}-elegible`} className="tracking-pixel" src={elegibleUrl} /> }
        { viewableUrl && <img ref={viewableImgRef} alt={`${imgAlt}-viewable`} className="tracking-pixel" /> }
      </a>
    </div>
  );
};

RegularAd.propTypes = {
  adParams: PropTypes.object,
  adSlot: PropTypes.string,
  adPlacement: PropTypes.object
};

export default RegularAd;

